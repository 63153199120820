import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Dialog } from '@reach/dialog';
import Layout from 'components/layout';
import SEO from 'components/seo';
import '@reach/dialog/styles.css';
import Video from 'components/video.tsx';
import Button from 'components/button';
import Carousel from '../components/carousel';

const Box = ({ image, description, titleLines }) => (
  <div className="text-center  mb-12 grid grid-rows-fourBoxLayout">
    <div className="w-32 h-32	my-0 mx-auto mb-12 ">
      <img src={`/images/${image}.png`} alt={image} className="w-full" />
    </div>
    <div className="grid grid-rows-twoBoxLayout">
      <div className="flex items-center justify-center">
        <h3 className="text-primary capitalize font-bold">
          {titleLines.map((title) => (
            <span className="block" key={title}>
              {title}
            </span>
          ))}
        </h3>
      </div>
      <div>
        <p className="text-primary font-normal text-small-para">
          {description}
        </p>
      </div>
    </div>
  </div>
);

const Index = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const onDismissSelectedImage = () => setSelectedImage(null);

  return (
    <Layout home>
      <SEO
        title="Tail Spend Management"
        description="The Maistro Tail Spend Management platform provides CPO's visibility and control over unmanaged spend"
      />
      <div>
        <div
          className="xl:pt-64 py-32"
          style={{
            backgroundColor: '#F8F6F5',
          }}
        >
          <div className="container">
            <div className="grid isMd:grid-cols-1 grid-cols-2 items-center gap-6">
              <div className="isMd:mb-12">
                <h1 className="text-primary normal-case font-bold">
                  Tail spend management procurement software
                </h1>
                <h4 className="text-primary normal-case text-xl my-12">
                  Maistro tail-spend software gives you the same visibility and
                  control in your small spend as you have with your strategic
                  spend. Giving you the power to buy better.
                </h4>
                <Link to="/platform/">
                  <Button
                    text="Our Platform"
                    bg="primary"
                    rightIcon="/images/chevronRightIcon.svg"
                  />
                </Link>
              </div>

              <div className="w-full hidden md:block">
                <Carousel
                  images={[
                    '/images/carousel/carousel-1.png',
                    '/images/carousel/carousel-2.png',
                    '/images/carousel/carousel-3.png',
                  ]}
                  interval={5000}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-secondary py-5">
          <div className="container">
            <div className="grid grid-cols-1 xl:grid-cols-2 items-center gap-6 p-6">
              <Video
                title="Maistro tail spend solution video"
                src="https://player.vimeo.com/video/551835154?h=69cc2ebe5f"
              />
              <div className="pl-8 mt-8 text-center xl:text-left">
                <h1 className="text-primary normal-case mb-2 font-bold">
                  An intelligent spend management procurement tool
                </h1>
                <h5 className="mb-4 mt-8 font-medium text-primary normal-case">
                  We’ve combined progressive technology with AI-powered insights
                  and deep expertise in an automated sourcing platform that
                  recommends and engages pre-vetted suppliers for you, making
                  competitive sourcing quick and easy for even the smallest
                  requirement.
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-stone py-16">
          <div className="container">
            <div className="pb-16">
              <h3 className="text-center normal-case">
                <span className="text-primary font-bold block whitespace-nowrap mb-3">
                  Autonomy with control:
                </span>
                Empower and improve{' '}
                <span className="text-primary font-bold">local</span> sourcing
                decisions while ensuring{' '}
                <span className="text-primary font-bold">centralised</span>{' '}
                visibility and control for CFOs and CPOs
              </h3>
            </div>
            <div className="flex items-center justify-between flex-wrap tab:justify-center">
              <div className="text-center w-80 mb-12">
                <div className="w-28 my-0 mx-auto mb-20 h-28">
                  <img
                    src="/images/project_builder_icon.png"
                    alt="contract_builder_icon"
                    className="w-full"
                  />
                </div>
                <p className="text-primary uppercase font-bold">
                  project builder
                </p>
                <p className="text-primary font-normal">
                  Smart specification forms automatically translate buyer needs
                  into a Statement of Requirements.
                </p>
              </div>
              <div className="text-center w-80 mb-12">
                <div className="w-28 my-0 mx-auto	mb-12 h-28">
                  <img
                    src="/images/response_builder_icon.png"
                    alt="contract_builder_icon"
                    className="w-full"
                  />
                </div>
                <p className="text-primary uppercase font-bold">
                  response builder
                </p>
                <p className="text-primary font-normal">
                  Quote and tender responses automatically convert into
                  detailed, easy to score submissions.
                </p>
              </div>
              <div className="text-center w-80 mb-12">
                <div className="w-28 my-0 mx-auto	mb-12 h-28">
                  <img
                    src="/images/contract_builder_icon.png"
                    alt="contract_builder_icon"
                    className="w-full"
                  />
                </div>
                <p className="text-primary uppercase font-bold">
                  contract builder
                </p>
                <p className="text-primary font-normal">
                  Requirements, responses and prices seamlessly convert to a
                  Statement of Work, replicating your Ts & Cs.
                </p>
              </div>
              <div className="text-center w-80 mb-12">
                <div className="w-28 my-0 mx-auto mb-20 h-28">
                  <img
                    src="/images/skills_builder_icon.png"
                    alt="contract_builder_icon"
                    className="w-full"
                  />
                </div>
                <p className="text-primary uppercase font-bold">
                  skills builder
                </p>
                <p className="text-primary font-normal">
                  Supplier skills & capabilities are automatically converted
                  into a structured profile to maximise potential for matching.
                </p>
              </div>
              <div className="text-center w-80 mb-12">
                <div className="w-28 my-0 mx-auto mb-12 h-28">
                  <img
                    src="/images/shortlist_builder_icon.png"
                    alt="contract_builder_icon"
                    className="w-full"
                  />
                </div>
                <p className="text-primary uppercase font-bold">
                  shortlist builder
                </p>
                <p className="text-primary font-normal">
                  Evaluation Model automatically assesses bid submissions in
                  terms of quality, cost and compliance.
                </p>
              </div>
              <div className="text-center w-80 mb-12">
                <div className="w-28 my-0 mx-auto mb-12 h-28">
                  <img
                    src="/images/insight_builder_icon.png"
                    alt="contract_builder_icon"
                    className="w-full"
                  />
                </div>
                <p className="text-primary uppercase font-bold">
                  insight builder
                </p>
                <p className="text-primary font-normal">
                  Dashboards automatically monitor progress while data enable
                  faster, better decisions.
                </p>
              </div>
            </div>
            <div className="text-center">
              <Link to="/platform/#features">
                <Button
                  text="View the Benefits"
                  bg="primary"
                  rightIcon="/images/chevronRightIcon.svg"
                />
              </Link>
            </div>
          </div>
        </div>

        <div className="bg-white py-16">
          <div className="container">
            <div className="grid isMd:grid-cols-1 grid-cols-2 items-center gap-6 p-6">
              <div className="isMd:mb-12">
                <h3 className="text-primary normal-case font-medium">
                  Bring your tail spend into sharp focus with powerful analytics
                  and data visualisation
                </h3>
                <h5 className="text-primary normal-case text-2xl my-12">
                  Real-time visibility across all categories and actionable
                  insights helps you identify new opportunities and hidden
                  savings.
                </h5>
                <Link to="/analysis/">
                  <Button
                    text="Learn from your Data"
                    bg="primary"
                    rightIcon="/images/chevronRightIcon.svg"
                  />
                </Link>
              </div>
              <div>
                <img
                  src="/images/dashboard.png"
                  alt="dashboard"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="py-16" style={{ backgroundColor: '#dbe4eb' }}>
          <div className="container">
            <div className="mb-16 text-center">
              <h1 className="text-4xl">
                <b>We are at your service.</b> How can we help you?
              </h1>
            </div>
            <div className="grid grid-cols-4 isMxl:grid-cols-2 isSm:grid-cols-1 isSm:justify-center gap-8">
              <Box
                image="reduce_cost"
                titleLines={['reduce', 'cost']}
                description="By creating an automated online process where buyers can
                    find invite-only suppliers who have provided their
                    credentials, we make sourcing faster and easier"
              />

              <Box
                image="reduce_risk"
                titleLines={['reduce', 'risk']}
                description="Our platform collects the data that buyers need from
                      suppliers to make fully-informed decisions, helping them
                      identify and eliminate risk"
              />
              <Box
                image="increase_compliance"
                titleLines={['Increase compliance']}
                description=" Risk doesn't end with vetting. By enabling a way to bring
                    more spend under management, we improve data quality and
                    reporting as well as SLA and compliance enforcement"
              />
              <Box
                image="responsible_suppliers"
                titleLines={['Find responsible suppliers']}
                description="  We enable buyers to spend more responsibly by matching
                    diverse suppliers that present more sustainable outcomes for
                    their organisation"
              />
            </div>
            <div className="text-center">
              <Link to="services/#wheredoyouwanttostart">
                <Button
                  text="Get Started"
                  rightIcon="/images/chevronRightIcon.svg"
                  bg="primary"
                />
              </Link>
            </div>
          </div>
        </div>

        <div className="bg-primary py-8">
          <div className="container">
            <div className="mt-4 flex items-center justify-between flex-wrap mb-12 isMd:flex-col isMd:justify-center">
              <div className="md-8 text-center w-2/5 mb-8 isMd:w-full">
                <Link to="/case-studies/one/" className="text-secondary">
                  <h3 className="normal-case text-secondary">
                    “We came looking for niche creative services. Not only did
                    we find a really innovative partner, the project came in
                    under budget too.”
                  </h3>
                </Link>
              </div>
              <div className="md-8 text-center w-2/5 mb-8 isMd:w-full">
                <Link to="/case-studies/three/" className="text-secondary">
                  <h3 className="normal-case text-secondary">
                    “Using the Maistro platform, we were able to quickly and
                    easily source only providers that meet our specific ESG
                    criteria”
                  </h3>
                </Link>
              </div>
            </div>
            <div className="text-center">
              <Link to="/case-studies/one/" className="text-secondary">
                <Button
                  text="View Case Studies"
                  rightIcon="/images/chevronRightIcon.svg"
                  bg="secdonary"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {selectedImage && (
        <Dialog onDismiss={onDismissSelectedImage}>
          <img src={selectedImage} alt="" />
          <div className="mt-8 text-center text-primary">
            <button
              type="button"
              onClick={() => setSelectedImage(null)}
              className="appearance-none px-4 py-2 bg-primary--3 inline-block uppercase text-lg hover:bg-primary--2 transition duration-200"
            >
              Close
            </button>
          </div>
        </Dialog>
      )}
    </Layout>
  );
};

export default Index;
