import React, { useState, useEffect, useRef } from 'react';
import ImageScroller, { defaultStatus } from 'react-image-scroller';

const Carousel = ({ images, interval }) => {
  const [scrollStatus, setScrollStatus] = useState(defaultStatus);
  const ref = useRef(null);
  useEffect(() => {
    const intReference = setInterval(() => {
      if (scrollStatus.next) {
        ref.current.next();
      } else {
        ref.current.scrollTo(0);
      }
    }, interval || 5000);
    return () => {
      clearInterval(intReference);
    };
  });

  return (
    <ImageScroller
      ref={ref}
      onChange={(newStatus) => {
        setScrollStatus(newStatus);
      }}
    >
      {images.map((image) => (
        <img
          key={`image-${image}`}
          src={image}
          alt="Section builder"
          className="w-full"
        />
      ))}
    </ImageScroller>
  );
};

export default Carousel;
